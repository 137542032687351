import React, { useEffect, useState } from "react";
import { Button, Icon, Form } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../features/User/UserSlice";
import styles from "../styles/Login.module.css";
import "../styles/global.css";
import logo from "../logo.png";
import { useHistory } from "react-router-dom";
import { Alert } from "../components/ui.js";

export default function LoginPage() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [termsAccepted, setTermsAccepted] = useState(false);

  function onSubmit(event) {
    event.preventDefault();
    if (!termsAccepted) {
      alert("Vous devez accepter les Conditions Générales d'Utilisation.");
      return;
    }
    const data = event.target.elements;
    dispatch(
      loginUser({
        apogee: data.apogee.value,
        cin: data.cin.value,
        birthDate: data.birthDate.value,
      })
    );
  }

  useEffect(() => {
    if (user.isLogged) {
      history.push("/profile");
    }
  }, [user.isLogged]);

  return (
    <div className="flex-center h100">
      <Form
        onSubmit={onSubmit}
        className={styles.loginForm}
        style={{
          background: "#FFFFFF",
          boxShadow: "0 10px 10px rgb(0 0 0 / 10%)",
          borderRadius: "8px",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "8px" }}>
          <img
            src={logo}
            alt="logo"
            width="128px"
            height="128px"
            style={{ margin: "8px", textAlign: "center" }}
          />
        </div>
        <Form.Field>
          <label>N° APOGEE / CNE (MASSAR)</label>
          <input placeholder="APOGEE / CNE (MASSAR)" name="apogee" />
        </Form.Field>
        <Form.Field>
          <label>CIN</label>
          <input placeholder="CIN" name="cin" />
        </Form.Field>
        <Form.Field>
          <label>DATE DE NAISSANCE</label>
          <input placeholder="Exemple: 01/01/2001" name="birthDate" />
        </Form.Field>
        <div style={{ marginTop: "16px" }}>
          <p style={{ fontSize: "12px", color: "#666" }}>
            Conformément à la loi 09-08, vous disposez d’un droit d’accès, de
            rectification et d’opposition au traitement de vos données
            personnelles. Ce traitement a été autorisé par la CNDP sous le n°
            <strong>A-GC-594/2024</strong>.
          </p>
          <Form.Field>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                id="termsCheckbox"
                style={{ marginRight: "8px" }}
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <label htmlFor="termsCheckbox">
                J’ai lu et j’accepte{" "}
                <a href="https://www.flshbm.ma/page/shrwt-alastkhdam-whmayt-albyanat-alshkhsyt" target="_blank" rel="noopener noreferrer">
                  les Conditions Générales d’Utilisation
                </a>
                , notamment la mention relative à la protection des données à
                caractère personnel.
              </label>
            </div>
          </Form.Field>
        </div>
        <Button
          color="violet"
          animated
          style={{ width: "100%", marginTop: "16px", }}
          loading={user.isFetching ? true : false}
        >
          <Button.Content visible>SE CONNECTER</Button.Content>
          <Button.Content hidden>
            <Icon name="arrow right" />
          </Button.Content>
        </Button>
        <Alert
          enabled={user.isRejected}
          type="danger"
          message="N° Apogee ou CIN non reconnu."
        />
      </Form>
    </div>
  );
}
